import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, NavigationEnd } from '@angular/router';
import { Company } from '../interfaces/company';
import { Customer } from '../interfaces/customer';
import { Global } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private global: Global
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.verifyLogin();
  }

  private verifyLogin(): boolean {

    if (this.global.isLogged()) {
      
      let customer: Customer = JSON.parse(localStorage.getItem('customer'));     
      let company: Company = JSON.parse(localStorage.getItem('company'));     

      if (!customer || !company) {      
        this.global.makeLogout();
      } else {
        return true;
      }

      return false;

    } else {      
      sessionStorage.setItem("redirectTo", window.location.href);
      this.router.navigate(['/account/login']);
      return false;
    }
  }
}
