import { Component, OnInit } from '@angular/core';
import { Global } from 'src/app/core/global.service';
import { InvokerService } from 'src/app/core/invoker.service';

@Component({
  selector: 'app-join-journey',
  templateUrl: './join-journey.component.html',
  styleUrls: ['./join-journey.component.css']
})
export class JoinJourneyComponent implements OnInit {

  journey: any = null;

  specificGroupUrlEncoded: string = '';

  constructor(
    public global: Global,
    private invoker: InvokerService
  ) {
 
  }

  ngOnInit(): void {
    this.getRandomJourney();
  }

  getRandomJourney() {
    this.invoker.entityName = "journeys/recommendations";
    this.invoker.getResources().subscribe((response: any) => {
      this.journey = response[0];
    });
  }

}
