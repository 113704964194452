import { Router } from '@angular/router';
import { OnInit, Component } from '@angular/core';
import { InvokerService } from 'src/app/core/invoker.service';
import { Global } from 'src/app/core/global.service';
import { Valuation } from 'src/app/interfaces/valuation';

@Component({
  selector: 'app-general-maturity-index',
  templateUrl: './general-maturity-index.component.html',
  styleUrls: ['./general-maturity-index.component.css']
})
export class GeneralMaturityIndexComponent implements OnInit {

  public generalMaturity: Valuation;

  constructor(
    private invoker: InvokerService,
    private router: Router,
    public global: Global
  ) { }

  ngOnInit(): void {
    this.global.getDefaultLanguage();
    this.getGeneralMaturityIndex();
  }

  getGeneralMaturityIndex(): void {
    this.invoker.entityName = 'companies/maturity';
    this.invoker.getResources().subscribe(
      (response: any) => {

        const _response: Valuation = response[0];

        if (_response.valuationStatus && _response.valuationStatus.name) {
          _response.valuationStatus.slug = _response.valuationStatus.name.replace(/ /g, "_").toLowerCase().trim();
        }

        _response.scoreDateTranslated = this.global.convertDataToDefaultLanguage(_response.scoreDate);

        this.generalMaturity = _response;
      },
      (error: any) => {
        this.generalMaturity = {};
        this.generalMaturity.error = error.error;
      }
    );
  }
}
