import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Global } from 'src/app/core/global.service';

@Component({
  selector: 'app-modal-subscription',
  templateUrl: './modal-subscription.component.html',
  styleUrls: ['./modal-subscription.component.css']
})
export class ModalSubscriptionComponent implements OnInit {

  public loading: boolean = false;
  public info:any;

  constructor(
    public global: Global,
    public bsModalRef: BsModalService,
    private navigateRouter: Router
  ) { }

  ngOnInit(): void {
    
  }

  goSubscriptionPage(): void {
    this.navigateRouter.navigate(['/subscription']);
    this.bsModalRef.hide();
  }

}
