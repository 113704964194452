import { Component, Input, OnInit } from "@angular/core";
import { InvokerService } from "./../../core/invoker.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-search-auto-complete',
  templateUrl: './search-auto-complete.component.html',
  styleUrls: ['./search-auto-complete.component.scss']
})
export class SearchAutoCompleteComponent implements OnInit {

  @Input() entityName;

  public resultList: any;
  public loading: boolean = false;

  constructor(private invoker: InvokerService, private router: Router) {}

  ngOnInit() {
    if (localStorage.getItem("searchHistory")) {
      let searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
      this.resultList = searchHistory;
    } else {
      this.getList("Unit"); // TODO: get recomendeds or other BI suggestion
    }
  }

  searchTerm(event) {
    this.getList(event.term);
  }

  listenChanges(event) {

    let searchHistory: Array<any> = [];

    if (localStorage.getItem("searchHistory")) {
      searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
    }

    if (searchHistory.some((evento) => evento.id === event.id)) {

      let newCompanyAtTop: Array<any> = searchHistory.filter(function (obj) {
        return obj.id === event.id;
      });

      let newComposition: Array<any> = searchHistory.filter(function (obj) {
        return obj.id !== event.id;
      });

      searchHistory = [...newCompanyAtTop, ...newComposition];
      
    } else {
      searchHistory.unshift(event);
    }

    localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
    this.resultList = searchHistory;

    this.router.navigate(["company", event.id]);
  }

  getList(term) {
    this.loading = true;

    if (term.length > 0) {
      this.invoker.entityName = this.entityName;
      this.invoker.getResources().subscribe(
        (response: any) => {
          this.loading = false;
          this.resultList = response;
        },
        (error: any) => {}
      );
    }
  }

}
