import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-footer-feed",
  templateUrl: "./footer-feed.component.html",
  styleUrls: ["./footer-feed.component.css"],
})
export class FooterFeedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }
  

}
