import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Global } from 'src/app/core/global.service';
import { InvokerService } from 'src/app/core/invoker.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {
  
  public loading: boolean = false;
  public title: string;
  public bodyTitle: string;
  public entityName: string;

  constructor(
    private invoker: InvokerService,
    public global: Global,
    public translate: TranslateService,
    public bsModalRef: BsModalService
  ) { }

  ngOnInit(): void {
 
  }

  deleteAction(): void {  

    this.invoker.entityName = this.entityName;
    this.invoker.deleteResource().subscribe(
      (response:any) => {      
        this.bsModalRef.hide();
      },
      (error:any) => {
        this.bsModalRef.hide();
      }
    );
  } 
}
