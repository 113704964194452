import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedComponentsModule } from "./shared-components/shared-components.module";
import { InvokerService } from "./core/invoker.service";
import { Global } from "./core/global.service";
import { ToastrModule } from "ngx-toastr";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateSharedModule } from "./translate.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpInterceptorService } from "./core/http.interceptor";
import * as moment from "moment";
import { registerLocaleData } from '@angular/common';
import l_pt from '@angular/common/locales/pt';
import l_en from '@angular/common/locales/en';
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { NgxGoogleAnalyticsModule } from "ngx-google-analytics";
import { NgxStripeModule } from 'ngx-stripe';

let actualLanguage: string = null;

if(!sessionStorage.getItem("language")) {

  let language = navigator.language || window.navigator.language;
  language = language.substring(0,2);

  if(language) {
    sessionStorage.setItem("language", language);
    actualLanguage = language;
  }
} else {
  actualLanguage = sessionStorage.getItem("language");
}

moment.locale(actualLanguage);

switch (actualLanguage) {
  case 'pt': {
    registerLocaleData(l_pt);
    break;
  }
  default: registerLocaleData(l_en);
}

// The translate loader needs to know where to load i18n files
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedComponentsModule,
    ToastrModule.forRoot(),
    BsDropdownModule.forRoot(),
    TranslateModule,
    TranslateSharedModule,
    GoogleTagManagerModule.forRoot({
      id: "GTM-PG77S7X",
    }),
    NgxGoogleAnalyticsModule.forRoot('G-YMEYJJXEB4'),
    // NgxStripeModule.forRoot('pk_test_6IPNOC6kWra6eCUDqsLSlTLC00KKJqlaEB'),
    NgxStripeModule.forRoot('pk_live_FIiQcrnbUCfASkENuvmiI8iP00rqcbDcDH')
  ],
  exports: [TranslateSharedModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: actualLanguage,
    },
    InvokerService,
    Global
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }