import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'src/app/core/global.service';
import { InvokerService } from 'src/app/core/invoker.service';
import { Valuation } from 'src/app/interfaces/valuation';

@Component({
  selector: 'app-valuation-measurement',
  templateUrl: './valuation-measurement.component.html',
  styleUrls: ['./valuation-measurement.component.css']
})
export class ValuationMeasurementComponent implements OnInit {

  public valuationMeasurement: Valuation;

  constructor(
    private invoker: InvokerService,
    private router: Router,
    public global: Global
  ) { }

  ngOnInit(): void {
    this.getValuationMeasurement();
  }

  getValuationMeasurement(): void {
    this.invoker.entityName = 'companies/valuations';
    this.invoker.getResources().subscribe(
      (response: any) => {

        const _response: Valuation = response[0];

        if (_response.valuationStatus && _response.valuationStatus.name) {
          _response.valuationStatus.slug = _response.valuationStatus.name.replace(/ /g, "_").toLowerCase().trim();
        }

        _response.scoreDateTranslated = this.global.convertDataToDefaultLanguage(_response.valuationDate);

        this.valuationMeasurement = _response;

      },
      (error: any) => {
        this.valuationMeasurement = {};
        this.valuationMeasurement.error = error.error;
      }
    );
  }

}
