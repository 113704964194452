import { Component, Input, OnInit } from "@angular/core";
import { Global } from "src/app/core/global.service";

@Component({
  selector: "app-footer-geral",
  templateUrl: "./footer-geral.component.html",
  styleUrls: ["./footer-geral.component.css"],
})
export class FooterGeralComponent implements OnInit {

  constructor(public global: Global) { }

  ngOnInit(): void { }

}
