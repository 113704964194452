import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-card-list",
  templateUrl: "./card-list.component.html",
  styleUrls: ["./card-list.component.css"],
})
export class CardListComponent implements OnInit {
  @Input() arrayData: Array<any> = [];
  @Input() title: string;
  @Input() group: string;

  constructor() { }

  ngOnInit(): void {
  }

  replaceSpaceToUnderline(string): string {
    return string.split(' ').join('-').toLowerCase();
  }

}
