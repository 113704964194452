import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-blank-content",
  templateUrl: "./blank-content.component.html",
  styleUrls: ["./blank-content.component.css"],
})
export class BlankContentComponent implements OnInit {
  public image: string = "assets/img/blank.svg";

  @Input() text = "Nothing here";
  @Input() description = "We didn't find any content";

  constructor() {}

  ngOnInit() {}
}
