import { Component, OnInit } from '@angular/core';
import { InvokerService } from 'src/app/core/invoker.service';

@Component({
  selector: 'app-most-pending-step-alert',
  templateUrl: './most-pending-step-alert.component.html',
  styleUrls: ['./most-pending-step-alert.component.css']
})
export class MostPendingStepAlertComponent implements OnInit {

  public pendingStep: any = null;

  constructor(private invoker: InvokerService) { }

  ngOnInit(): void {
    this.getMostPendingStep();
    
  }

  getMostPendingStep(): void {
    this.invoker.entityName = "journey-steps/execution";
    this.invoker.getResources().subscribe((response:any) => {

      if(response.length > 0) {
        this.pendingStep = response[0];
      }

    });
  }

}
