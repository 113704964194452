import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Global } from "../../../core/global.service";
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {

  public info = this.global.getLoggedUser();

  public productionMode: boolean = true;
  
  constructor(public global: Global, private router: Router) {}

  menuToUse(): string {

    if (this.router.url.indexOf("onboarding") != -1) {
      return "onboarding";
    } else if (this.router.url.indexOf("login") != -1) {
      return "login";
    } else if (this.router.url.indexOf("incubator") != -1) {
      return "incubator";
    } else if (!localStorage.getItem("token")) {
      return "not-logged";
    } else {
      return "logged";
    }    
  }

  ngOnInit() {}
  
}
