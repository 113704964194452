import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Global } from 'src/app/core/global.service';
import { InvokerService } from 'src/app/core/invoker.service';
import { Company } from 'src/app/interfaces/company';
import { Customer } from 'src/app/interfaces/customer';

@Component({
  selector: 'app-navbar-incubator',
  templateUrl: './navbar-incubator.component.html',
  styleUrls: ['./navbar-incubator.component.css']
})
export class NavbarIncubatorComponent implements OnInit {

  public user: Customer;
  public company: Company;
  public companies: Array<Company>; 
  public customer: Customer = this.global.getLoggedUser();

  public defaultLanguage;

  constructor(
    public global: Global, 
    private router: Router, 
    private invoker: InvokerService,
    private translate: TranslateService
  ) {
    this.global.user$.subscribe(user => {
      this.user = user;
    })
    this.global.company$.subscribe(company => {
      this.company = company;
    })
  }

  ngOnInit(): void {
    if (this.user) {

      if (this.user.language.name) {
        this.defaultLanguage = this.user.language.name;
      } else {
        this.defaultLanguage = this.user.language;
      }
    }

    this.getCompanies();
  }

  changeDefaultLanguage(language): void {

    let body = {
      email: this.user.email,
      language: language
    };

    this.invoker.entityName = "customers/changelanguage";
    this.invoker.createResource(body).subscribe(
      (response: any) => {

        this.defaultLanguage = language;
        this.user.language = language;

        this.global.setUserData(this.user);
        location.reload();

      },
      (error: any) => { }
    );
  }

  getCompanies(): void {
    this.invoker.entityName = "companies/customers";
    this.invoker.getResources().subscribe(
      (response: any) => {
        this.companies = response;        
      },
      (error: any) => { }
    );
  }


  changeCompany(item): void {
    let body = {
      "loginDefault": "Y"
    };

    this.invoker.entityName = `companies/${item.company.id}/customers`;
    this.invoker.patchResourceDynamic(item.company.id, body, 'middle').subscribe(
      (response: any) => {

        this.global.setCompanyData(response.company);
        this.customer.company = response.company;
        this.global.setUserData(this.customer);

        this.translate.get("profile_company.changed_company").subscribe((res: string) => {
          this.global.toaster.success(res);
        });
        
        location.reload();
      },
      (error: any) => { }
    );
  }

  logout(): void {
    this.global.signout();
  }

  stopDropdown(event): void {
    event.stopPropagation();
  }

}
