import { Injectable } from '@angular/core';
import { Global } from './global.service';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalSubscriptionComponent } from '../shared-components/subscriptions/modal-subscription/modal-subscription.component';
import { catchError } from 'rxjs/operators';
import { ModalUnauthorizedComponent } from '../shared-components/subscriptions/modal-unauthorized/modal-unauthorized.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  public bsModalRef: BsModalRef;
  constructor(
    private router: Router,
    public global: Global,
    private translate: TranslateService,
    private modalService: BsModalService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  

    this.global.setError(null);
    
    const xhr = request.clone({
      withCredentials: true
    });

    return next.handle(xhr).pipe(catchError((error, caught) => {
      //intercept the respons error and displace it to the console
      this.handleAuthError(error);
      return of(error);
    }) as any);
  }

  /**
   * manage errors
   * @param err
   * @returns {any}
   */
  private handleAuthError(err: HttpErrorResponse): void {
    if (err.error.code) {

      switch (err.error.code) {
        case 97:
          this.bsModalRef = this.modalService.show(ModalSubscriptionComponent, {initialState: {info: err.error}});
          this.bsModalRef.setClass('modal-lg');
          break;
          
        case 121:
        case 301:
        case 302:
        case 393:
        case 499:
        case 541:
          break;
        case 901:  
          this.global.setError(err.error);
          this.callToastr(err.error);
          break;
        case 904:
          this.callUnauthorizedError(err);
          break;

        case 403:
        case 503:
          this.callSessionError(err.error);
          break;

        default:
          this.callToastr(err.error);
          break;
      }
    }
    else {
      switch (err.error.status) {

        case 403:
        case 503:
          this.callUnauthorizedError(err);
          this.callSessionError(err.error);
          break;

        default:
          this.callToastr(err.error);
          break;
      }
    }

    throw err;
  }

  private callUnauthorizedError(error) {

    let pieces = error.url.split("/");
    let path = pieces[pieces.length - 1];
    let Antpath = pieces[pieces.length - 2];

    // verifying if path contains incubator/list-employees
    if(Antpath =='company' && path.includes('employee') && window.location.pathname == '/incubator/list-employees') {
      this.bsModalRef = this.modalService.show(ModalUnauthorizedComponent, {backdrop: 'static', keyboard: false, initialState: {info: error.error, general: 'list_employees'}});
      this.bsModalRef.setClass('modal-lg');
    }

    // verifying if path contains profile/company when user try to update company profile without be admin
    if(path.includes('companies') && window.location.pathname == '/profile/company') {
      this.callToastr(error.error);
    }

    if (window.location.pathname.includes('/journey-group')) {
      this.bsModalRef = this.modalService.show(ModalUnauthorizedComponent, {backdrop: 'static', keyboard: false, initialState: {info: error.error, general: 'journeys'}});
      this.bsModalRef.setClass('modal-lg');
    }
    else if(Antpath == 'programs') {
      this.callToastr(error.error);
      this.router.navigate(['/ecosystem/program/listing']);
    }
  
    switch (path) {
      case "challenges":
      case "programs":
        this.bsModalRef = this.modalService.show(ModalUnauthorizedComponent, {backdrop: 'static', keyboard: false, initialState: {info: error.error, general: 'programs'}});
        this.bsModalRef.setClass('modal-lg');
        break;

      default:
        break;
    }

  }

  private callSessionError(error) {

    let pathClean = error.path.replace(/[0-9]/g, '');
    let lastChar = pathClean.slice(-1);

    if (lastChar == "/") {
      pathClean = pathClean.slice(0, -1);
    }

    switch (pathClean) {
      case "/api/feed":
      case "/api/companies":
      case "/api/customers":
        this.global.makeLogout();
        break;

      default:
        break;
    }

  }

  private callToastr(error: any) {

    error.message = (!error.message) ? error.messageBack : error.message;

    if (error && error.type == "WARNING") {
      this.global.toaster.warning(error.message, error.type, {
        positionClass: "toast-bottom-left",
      });
    }
    else if ((error && error.type == "ERROR")) {
      this.translate.get("general_error.alert").subscribe((res: string) => {
        this.global.toaster.error(error.message, res, {
          positionClass: "toast-bottom-left",
        });
      });
    }
    else {
      this.translate.get("general_error.alert").subscribe((res: string) => {
        this.global.toaster.error(error.message, res, {
          positionClass: "toast-bottom-left",
        });
      });
    }
  }
}
