import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Global } from 'src/app/core/global.service';
import { InvokerService } from 'src/app/core/invoker.service';

@Component({
  selector: 'app-invite-friend',
  templateUrl: './invite-friend.component.html',
  styleUrls: ['./invite-friend.component.css']
})
export class InviteFriendComponent implements OnInit {

  public entity: any = {};
  public onLoading = false;

  constructor(
    private router: Router,
    private invoker: InvokerService,
    public global: Global,
    private translate: TranslateService
  ) {
    this.entity.language = sessionStorage.getItem("language");
  }

  ngOnInit(): void {
  }

  saveData(): void {
    this.onLoading = true;

    let body = {
      firstName: this.entity.first_name.trim(),
      email: this.entity.email.trim(),
      language: this.entity.language.substring(0, 2),
      typeInvitationId: 2,
      template: "INVITE_FRIEND"
    };

    this.invoker.entityName = "email/invitation";
    this.invoker.createResource(body).subscribe(
      (response: any) => {

        this.onLoading = false;

        this.entity.first_name = '';
        this.entity.last_name = '';
        this.entity.email = '';

        this.translate
          .get("actions.invite_friend_success")
          .subscribe((res: string) => {
            this.global.toaster.success(res);
          });

      },
      (error: any) => {
        this.onLoading = false;

        this.entity.first_name = '';
        this.entity.last_name = '';
        this.entity.email = '';
      }
    );
  }

}
