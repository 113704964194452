import { Component, Input, OnInit } from '@angular/core';
import { Global } from 'src/app/core/global.service';
import { Error } from 'src/app/interfaces/error';

@Component({
    selector: 'app-handle-errors',
    templateUrl: './handle-errors.component.html',
    styleUrls: ['./handle-errors.component.css']
})
export class HandleErrorsComponent implements OnInit {

    @Input() field: string;

    public error: Error;

    constructor(
        public global: Global
    ) {

    }

    ngOnInit(): void {
        
        if (this.global.error && this.global.error.subErrors.length > 0) {
            this.error = this.global.error;
        }         
    }

}
