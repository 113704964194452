import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Global } from 'src/app/core/global.service';
import { InvokerService } from 'src/app/core/invoker.service';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
//   styleUrls: ['./action-buttons.component.css']
})
export class ActionButtonsComponent implements OnInit {

  @Input() buttons: any;
  @Input() id: number;
  @Input() connected: boolean;
  @Input() invitation: boolean;
  @Input() follow: string;
  @Input() followed: string;

  public defaultMessage:string = '';
  public loading: boolean = false;
 
  constructor(
    private router: Router,
    private invoker: InvokerService,
    public global: Global,
    public http: HttpClient,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    
  }

  processButton(name: string, value:number = null): void {

    switch (name) {
      case "view":
        this.router.navigate([]).then(() => {
          window.open("/company/" + value, "_blank");
        });

        break;
      case "remove":
        this.invoker.entityName = "companies/connections";
        this.invoker.deleteResource(value).subscribe(
          (response: any) => {
            let title: string;
            let msg: string;
  
            this.translate
              .get("actions.success_title")
              .subscribe((res: string) => {
                title = res;
              });
  
            this.translate
              .get("actions.success_msg_remove")
              .subscribe((res: string) => {
                msg = res;
              });
  
            this.global.toaster.success(msg, title);
            this.followed = 'N';
            this.connected = false;

          },
          (error: any) => {
            let title: string;
            let msg: string;
  
            this.translate.get("actions.error_title").subscribe((res: string) => {
              title = res;
            });
  
            this.translate
              .get("actions.error_msg_remove")
              .subscribe((res: string) => {
                msg = res;
              });
  
            this.global.toaster.success(msg, title);            
          }
        );
        break;
      case "withdraw":
        this.invoker.entityName = "companies/invitations";
        this.invoker.deleteResource(value).subscribe(
          (response: any) => {
            let title: string;
            let msg: string;
  
            this.translate
              .get("actions.success_title")
              .subscribe((res: string) => {
                title = res;
              });
  
            this.translate
              .get("actions.success_msg_withdraw")
              .subscribe((res: string) => {
                msg = res;
              });
  
            this.global.toaster.success(msg, title);
          },
          (error: any) => {
            let title: string;
            let msg: string;
  
            this.translate.get("actions.error_title").subscribe((res: string) => {
              title = res;
            });
  
            this.translate
              .get("actions.error_msg_withdraw")
              .subscribe((res: string) => {
                msg = res;
              });
  
            this.global.toaster.error(msg, title);
          }
        );

        break;
      case "accept":
      case "deny":
        this.translate.get("actions.invitation_default_message").subscribe((res: string) => {
          this.defaultMessage = res;
        });

        this.invoker.entityName = "companies/invitations";
        this.invoker.createResource({
            companyInvitedId: value,
            message: this.defaultMessage
            }).subscribe(
            (response:any) => {
                let title: string;
                let msg: string;
        
                this.translate.get("actions.success_title").subscribe((res: string) => {
                title = res;
                });
        
                this.translate
                .get("actions.success_msg_invitation")
                .subscribe((res: string) => {
                    msg = res;
                });
        
                this.global.toaster.success(msg, title);
            },
            (error: any) => {
                let title: string;
                let msg: string;
        
                this.translate.get("actions.error_title").subscribe((res: string) => {
                title = res;
                });
        
                this.translate
                .get("actions.error_msg_invitation")
                .subscribe((res: string) => {
                    msg = res;
                });
        
                this.global.toaster.error(msg, title);
            }
        );
    
        break; 
      case "follow":
        this.loading = true;
        this.invoker.entityName = `companies/connections`;
        let body = {
          "companyId": value,
          "follow": "Y"
        };
    
        this.invoker.createResource(body).subscribe(
          (response: any) => {
            let title: string;
            let msg: string;
            this.translate.get("actions.success_title").subscribe((res: string) => {
              title = res;
              });
      
              this.translate
              .get("actions.success_follow")
              .subscribe((res: string) => {
                  msg = res;
              });
      
            this.global.toaster.success(msg, title);

            this.loading = false;
            this.followed = 'Y';            
          },
          (error: any) => {
            this.loading = false;
    
          }
        );
        break; 
      default:
        break;
    }
  }

}