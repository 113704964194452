import { Component, OnInit } from "@angular/core";
import { Customer } from "src/app/interfaces/customer";
import { Router } from "@angular/router";
import { InvokerService } from "src/app/core/invoker.service";
import { Global } from "src/app/core/global.service";

@Component({
  selector: "app-customer-connect",
  templateUrl: "./customer-connect.component.html",
  styleUrls: ["./customer-connect.component.css"],
})
export class CustomerConnectComponent implements OnInit {

  public info: Customer = this.global.getLoggedUser();

  public users = [
    {id: 433, firstName: 'Paulino', lastName: 'de Franca', headline: "I develop startups to lifes get better", photo: this.global.env.s3_bucket_basepath+"/nextepitweb/customer/545_cust.png"},
    {id: 275, firstName: 'Fernando', lastName: 'Goulart', headline: "I help startups to lifes get better", photo: this.global.env.s3_bucket_basepath+"/nextepitweb/customer/275_cust.jpeg"},
    {id: 353, firstName: 'Laerte', lastName: 'TurT', headline: "I develop startups to lifes get better", photo: this.global.env.s3_bucket_basepath+"/nextepitweb/customer/96b6cca2-6259-4b1e-b4bd-a5daf27a1980-128x128.jpg"},
    {id: 352, firstName: 'Rafael', lastName: 'Souza', headline: "I develop startups to lifes get better", photo: this.global.env.s3_bucket_basepath+"/img/default/person-profile.jpg"},
  ];

  constructor(
    private invoker: InvokerService,
    public global: Global,
    private router: Router
  ) {

  }

  ngOnInit(): void {

  }

  redirectToUser(userId) {

    this.router.navigate([`/user/${userId}`]);
  }

 
}
