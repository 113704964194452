import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { InvokerService } from "src/app/core/invoker.service";
import { Global } from "src/app/core/global.service";
import { Company } from "src/app/interfaces/company";
import { CompanyControl } from "src/app/interfaces/views/company-control";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-company-connect",
  templateUrl: "./company-connect.component.html",
  styleUrls: ["./company-connect.component.css"],

})
export class CompanyConnectComponent implements OnInit {

  public companies: Array<Company>;

  constructor(
    private invoker: InvokerService,
    public global: Global,
    private router: Router,
    private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.getCompanyConnect();
  }

  getCompanyConnect(): void {
    this.invoker.entityName = 'companies/connection-recommendations?amount=5';
    this.invoker.getResources().subscribe((response: any) => {
      this.companies = response;
    });
  }

  followCompany(company: CompanyControl): void {
    this.invoker.entityName = `companies/connections`;
    let body = {
      "companyId": company.id,
      "follow": "Y"
    };

    this.invoker.createResource(body).subscribe(
      (response: any) => {
        company.followBtn = true;
      },
      (error: any) => {

      }
    );
  }

  sendInvitation(company: CompanyControl): void {
    let defaultMessage: string;
    this.translate.get("actions.invitation_default_message").subscribe((res: string) => {
      defaultMessage = res;
    });

    this.invoker.entityName = "companies/invitations";
    this.invoker.createResource({
      companyInvitedId: company.id,
      message: defaultMessage
    }).subscribe(
      (response: any) => {
        let title: string;
        let msg: string;
        company.connectedBtn = true;


        this.translate.get("actions.success_title").subscribe((res: string) => {
          title = res;
        });

        this.translate
          .get("actions.success_msg_invitation")
          .subscribe((res: string) => {
            msg = res;
          });

      },
      (error: any) => {
        let title: string;
        let msg: string;

        this.translate.get("actions.error_title").subscribe((res: string) => {
          title = res;
        });

        this.translate
          .get("actions.error_msg_invitation")
          .subscribe((res: string) => {
            msg = res;
          });

        this.global.toaster.error(msg, title);
      }
    );
  }

  redirectToCompany(companyId: number): void {
    this.router.navigate([`/company/${companyId}`]);
  }
}
