import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'src/app/core/global.service';
import { InvokerService } from 'src/app/core/invoker.service';
import { SubscriptionType } from 'src/app/interfaces/subscription-type';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  public subscriptionTypes: SubscriptionType;
  public paying: boolean = true;
  public infoPayment: any;
  public cancelOption: number;
  public cancelNote: string = '';
  
  constructor(
    public global: Global,
		private invoker: InvokerService,
    private navigateRouter: Router
  ) { }

  ngOnInit(): void {
    this.getSubcriptionTypes();
    this.getSubscriptionPlan();
  }

  getSubcriptionTypes():void {
		this.invoker.entityName = 'subscriptions-types';
		this.invoker.getResources().subscribe((response:any) => {
			this.subscriptionTypes = response.map(item => {
        
        switch (item.id) {
          case 1:
            item.amountF = item.amount;
            item.totalAmountF = item.totalAmount;
            break;
          case 2:
            item.amountF = item.amount;
            item.totalAmountF = item.totalAmount*12;
          break;
          case 3:
            item.amountF = item.amount/12;
            item.totalAmountF = item.totalAmount;
            break;
          default:
            break;
        }

        return item;
      });
			
		});
	}

  selectPlan(planType): void {
    localStorage.setItem('planSelected',JSON.stringify(planType));
    this.navigateRouter.navigate([`/subscription-payment`]);
  }


  getSubscriptionPlan(): void {
    this.invoker.entityName = 'subscription/companies/infostatuspayment';
		this.invoker.getResources().subscribe((response:any) => {
      if (response && response.infoStatusPayment.statusSubscriptionId == 1) {
        this.infoPayment = response.infoStatusPayment;
      }
    });    
  }

  cancelPlan(): void {
    let body = [{
      cancellationTypeId: this.cancelOption,
      note: this.cancelNote
    }];
    
    this.invoker.entityName = 'company/cancel-subscription';
		this.invoker.createResource(body).subscribe((response:any) => {
      this.infoPayment = null;
    }); 
  }
}
