
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { InvokerService } from 'src/app/core/invoker.service';

@Component({
  selector: 'app-chart-radar',
  templateUrl: './chart-radar.component.html',
  styleUrls: ['./chart-radar.component.css']
})
export class ChartRadarComponent implements OnInit {
 
  @Input() radarChartLabels: any = [];
  @Input() radarChartValue: any = [];
  
  // Radar
  public radarChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
  };

  public radarChartType: ChartType = 'radar';
  public radarChartData: ChartData<'radar'> | any = {};

  constructor(
    private invoker: InvokerService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    let titleChart: string;
    this.translate.get("profile_company.maturity").subscribe((res: string) => {
      titleChart = res;
    });
    this.radarChartData.labels = this.radarChartLabels;
    this.radarChartData.datasets = [ {
      data: this.radarChartValue,
      label: titleChart,
      fill: true 
    }];
  }
 
}
