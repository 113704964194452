import { Component, OnInit } from "@angular/core";
import { InvokerService } from "./../../core/invoker.service";

@Component({
  selector: "app-team",
  templateUrl: "./team.component.html",
  styleUrls: ["./team.component.css"],
})
export class TeamComponent implements OnInit {
  public employees: any;

  constructor(private invoker: InvokerService) {}

  ngOnInit() {
    this.getEmployees();
  }

  getEmployees() {
    this.invoker.entityName = "company/employee?pageSize=5&pageNumber=1";
    this.invoker.getResources().subscribe((response) => {
      this.employees = response;
    });
  }
}