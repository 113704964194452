import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'src/app/core/global.service';
import { Customer } from 'src/app/interfaces/customer';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  public user: Customer;

  constructor(public global: Global, private router: Router) {
    this.global.user$.subscribe(user => {
      this.user = user;
    })
  }

  ngOnInit(): void {

  }

}
