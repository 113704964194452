import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { InvokerService } from "./../../core/invoker.service";
import { Router } from "@angular/router";
import { Global } from "src/app/core/global.service";
import { Company } from "src/app/interfaces/company";
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, tap } from "rxjs/operators";
import { concat, Observable, of, Subject } from "rxjs";
@Component({
  selector: "app-search-company",
  templateUrl: "./search-company.component.html",
  styleUrls: ["./search-company.component.css"],
})
export class SearchCompanyComponent implements OnInit {

  public resultList = new Observable<any>();
  public typeInput$ = new Subject<string>();

  public loading: boolean = false;

  @Input() selectedClass;
  public entity: any = {};
  public selectedCompanyItem: number;
  public classToUse: string;
  public isCompanySelected: boolean = false;

  public term: string | null = null;

  @Output() newItemEvent = new EventEmitter<string>();
  @Output() isCreatedCompany = new EventEmitter<boolean>();

  constructor(
    private invoker: InvokerService,
    private router: Router,
    public global: Global
  ) {

  }

  ngOnInit(): void {

    this.getList();

    if (this.selectedClass == 'search-company') {
      this.classToUse = "search-company";
      if (localStorage.getItem("searchHistory")) {
        let searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
        // this.resultList = of(searchHistory);
      } else {
        // this.getInitialSearch("hubs"); // TODO: aqui vem algum BI ou recomendados no futuro
      }
    } else if (this.selectedClass == 'search-company2' && this.isCompanySelected) {
      this.classToUse = "search-company2 form-control";
      const company = this.global.getSelectedCompany();
      this.selectedCompanyItem = company.id;
      this.resultList = of([company]);
    } else {
      this.classToUse = "search-company2 form-control";
    }
  }

  createCompany(value: boolean): void {
    this.isCreatedCompany.emit(value);
  }

  setCompanySelected(value: boolean): void {
    this.isCompanySelected = value;
    this.createCompany(!this.isCompanySelected);
  }

  addNewItem(value: string): void {
    this.newItemEvent.emit(value);
  }

  searchFnEvent(words: string): string {
    return words;
  }

  searchTerm(event): void {

    if (event.term) {
      this.term = event.term;
      this.getInitialSearch(event.term);
    }

  }

  listenChanges(event): void {

    if (this.selectedClass == 'search-company') {
      let searchHistory: Array<any> = [];

      if (localStorage.getItem("searchHistory")) {
        searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
      }

      if (searchHistory.some((evento) => evento.id === event.id)) {

        let newCompanyAtTop: Array<any> = searchHistory.filter(function (obj) {
          return obj.id === event.id;
        });

        let newComposition: Array<any> = searchHistory.filter(function (obj) {
          return obj.id !== event.id;
        });

        searchHistory = [...newCompanyAtTop, ...newComposition];

      } else {
        searchHistory.unshift(event);
      }

      localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
      // this.resultList = searchHistory;

      event.resultType == "Company" ? this.router.navigate(["company", event.id]) : this.router.navigate(["user", event.id]);

    } else {
      this.global.setSelectedCompany(event);
      this.isCreatedCompany.emit(true);
    }
  }

  getInitialSearch(term: string) {
    let entityName = '';
    this.selectedClass == 'search-company' ? entityName = `people?name=${term}` : entityName = `companies?name=${term}`;
    this.invoker.entityName = entityName;
    this.invoker.getResource(null, null, term)
      .subscribe(
        (response) => {
          this.resultList = of(response)
        }
      )
  }

  getList(): void {

    if (this.term != null) {
      this.loading = true;
    }
    
    this.resultList = this.typeInput$.pipe(
      tap(term => {
        this.loading = true;
        this.term = term
      }),
      filter((item: any) => item && item.length > 2),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((term) => {
        let entityName = '';
        this.selectedClass == 'search-company' ? entityName = `people?name=${term}` : entityName = `companies?name=${term}`;
        this.invoker.entityName = entityName;
        () => this.loading = false;
        return this.invoker.getResource(null, null, term).pipe(
          catchError(() => of([])),
          finalize(() => {
            this.loading = false;
          })
        )
      })
    );

  }
}
