import { TranslateService } from '@ngx-translate/core';
import { InvokerService } from './../../../core/invoker.service';
import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Customer } from 'src/app/_models';
import { Global } from 'src/app/core/global.service';

@Component({
  selector: 'app-modal-unauthorized',
  templateUrl: './modal-unauthorized.component.html',
  styleUrls: ['./modal-unauthorized.component.css']
})
export class ModalUnauthorizedComponent implements OnInit {

  public loading: boolean = false;
  public info: any;
  public sentEmail: boolean = false;
  public countDown: number;
  public displayCountDown: any;
  public customerAdmin: Customer;
  public general: string;

  constructor(
    public global: Global,
    public bsModalRef: BsModalService,
    public invoker: InvokerService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {  
    this.getCompanyAdmin();
  } 

  refreshPage(): void {
    location.reload();
  }

  getCompanyAdmin(): void {
    this.invoker.entityName = "companies/customers/info?active=yes&admin=yes";
    this.invoker.getResources().subscribe((response:any) => {
      this.customerAdmin = response;
    })
  }

  requestAcess(user: Customer): void {
    let url = window.location.host;
    let body = {};
    this.translate.get(`unauthorized.${this.general}`).subscribe(
      (res: string) => {
        body = {
          "customerId": user.id,
          "firstName": user.firstName,
          "lastName": user.lastName,
          "email": user.email,
          "link": `${url}/incubator/list-employees`,
          "template": "REQUEST_ACCESS",
          "general": res
        };

        this.invoker.entityName = "email/template";
        this.invoker.createResource(body).subscribe(
          (response:any) => {
            this.sentEmail = true;
            this.timer(1);   
            this.translate
            .get("unauthorized.send_request")
            .subscribe((res: string) => {
              this.global.toaster.success(res);
            });
          },
          (error:any) => {
            this.sentEmail = false;
          }
        )    
    });    

  }

  timer(minute: number): void {
    let totalTimer: number = minute * 60;
    let seconds: any = "0";
    let statusSeconds: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      totalTimer--;
      statusSeconds != 0 ? statusSeconds-- : statusSeconds = 59;
      statusSeconds < 10 ? seconds = "0" + statusSeconds : seconds = statusSeconds;

      this.displayCountDown = `${prefix}${Math.floor(totalTimer / 60)}:${seconds}`;

      if (totalTimer == 0) {
        clearInterval(timer);
        this.sentEmail = false;
      }
    }, 1000);
  }

}
